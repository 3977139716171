<template>
  <div>
    <div>
      <div class="common-file-list-container"
           v-for="iter in fileList" :key="iter.url">
        <div class="common-file-list-item-left">
          <div style="display: flex;flex: 1;">
            <div>
              <custom-show-icon :filename="iter.name"></custom-show-icon>
            </div>
            <div style="margin-left: 14px;flex: 1;font-size: 14px;">{{ iter.name }}</div>
          </div>
          <div @click="onPreviewHandler(iter)">
            <custom-show-icon custom-image custom-image-resource="download"></custom-show-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {exportPicture, isImage} from "@/utils/common";
import helper from "@/utils/helper";
import '@/styles/LPopup.scss'
import CustomShowIcon from "@/components/CustomShowIcon.vue";

export default {
  name: 'FileList',
  components: {CustomShowIcon},
  props: {
    designData: {
      type: Object,
      default(){
        return {}
      }
    }
  },
  data(){
    return {

    }
  },
  computed: {
    fileList(){
      return this.showFileList(this.designData)
    }
  },
  methods: {
    isImage,
    /**
     * 点击文件
     * @param file
     */
    onPreviewHandler(file){
      const { url, name } = file
      exportPicture(url, name)
    },
    /**
     *
     */
    showFileList(item){
      const filesStr = item?.files || ''
      const files = []
      filesStr.split(',').forEach(iter => {
        const name = ((iter || '').split('/') || []).reverse()[0]
        iter && files.push({
          name: name,
          url: helper.getSourceUri(iter),
          value: iter
        })
      })
      return files
    },
  }
}
</script>

<style scoped>

</style>